import React from 'react'
import './Footer.scss'


function Footer() {
  return (
    <div className='footer'>
      <div className='footer_container'>

          <p>Footer Content</p>
      </div>
    
    </div>
  )
}

export default Footer
